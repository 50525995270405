import React, {memo} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import JoinOurTeam from "../components/JoinOurTeam";
import WorkWithUsSlider from "../components/who_work_with_us/WorkWithUsSlider";
import TheyClapUsSlider from "../components/they_clap_us/TheyClapUsSlider";
import FooterDivider from "../components/FooterDivider";
import GenericHeader from "../components/header_section/GenericHeader";
import Container from "../components/layouts/Container";
import Row from "../components/layouts/Row";
import RowItem from "../components/layouts/RowItem";
import styled from "styled-components";
import {fluidSizing, fluidTypography, media} from "../components/styles/MediaMixins";
import Title from "../components/title/Title";
import Paragraph from "../components/paragraph/Paragraph";

const AboutTitle = styled(Title)`
  margin-top: ${29 / 16}rem;
  ${media.widescreen`margin-top: ${96 / 16}rem`};
`

const Name = styled.p`
  ${fluidTypography(24, 30, 768, 1920)};
  ${fluidSizing('line-height', 30, 39)};
  color: #535B6C;
  font-weight: bold;
  margin: 0;
  text-align: left;
  white-space: pre;
  padding-left: 2px;
`

const MemberTitle = styled.p`
  font-size: 1rem;
  line-height: 1.8rem;
  color: #03030366;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: auto;
  text-align: left;
  padding-left: 2px;
`

const AboutParagraph = styled(Paragraph)`
  margin-top: ${10 / 16}rem;
  margin-bottom: ${29 / 16}rem;
  ${media.widescreen`
    margin-top: ${15 / 16}rem;
    margin-bottom: ${60 / 16}rem
  `};
`

const MemberCard = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  width: ${264 / 16}rem;
   ${media.desktop`
     width: 20%;
  `};
  & > div {
    margin: 0;
  }
`

const MemberRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  max-width: ${1600 / 16}rem;
`

export default memo(({data}) => {
  const {markdownRemark: post} = data;

  const memberCards = post.frontmatter.members.map((member, index) => (
    <MemberCard key={index}>
      <PreviewCompatibleImage
        imageInfo={{
          image: member.img,
          alt: `image of member ${member.name}`,
        }}
      />
      <Name>
        {member.name.split(' ').join('\n')}
      </Name>
      <MemberTitle>
        {member.title}
      </MemberTitle>
    </MemberCard>
  ));

  return (
    <Layout local_title={'About Us - Cypress'}
            meta_image={post.frontmatter.title_image.childImageSharp.fluid.src}
            meta_image_width={post.frontmatter.title_image.childImageSharp.fluid.presentationWidth}
            meta_image_height={post.frontmatter.title_image.childImageSharp.fluid.presentationHeight}
            meta_keyword={`cypress team, about cypress, about us text, clients reviews, IT testimonials, app development team, team of creatives, customers opinions`}
            meta_description={`Get familiar with our Cypress team. Learn our working culture,  beliefs, professionalism level, and our approach to your needs. We care for your success.`}>
      <GenericHeader title={post.frontmatter.title}
                     subtitle={`Cypress is a mobile app development company based in Armenia. We focus especially 
                                on mobile apps because they add huge value to businesses, by making it accessible to the clients. 
                                At Cypress, we build customer-centric software products (both for B2B and B2C). 
                                We know that our customers trust us the most valuable things - time, energy, and money. 
                                We shape a clear vision of our clients’ business objectives and create solutions that drive real value. 
                                Our IT products are developed, taking into consideration specific business needs, target audience, time, and budget.`}
                     title_image={post.frontmatter.title_image}
                     title_image_mobile={post.frontmatter.title_image_mobile}/>
      <Container breakpoint={'desktop'}>
        <Row breakpoint={'desktop'}>
          <AboutTitle>We Are Cypressers</AboutTitle>
        </Row>
        <Row breakpoint={'desktop'}>
          <AboutParagraph>
            Cypress is a team of gifted individuals who come together on a shared passion for technology.
            The imagination of our creative designers and the innovative mind of our talented developers are
            the guarantees of making your dreams come to reality.
            <br/>
            <br/>
            Like the Cypress tree, we grow steadily, keeping our evergreen nature. Our team doesn’t only
            consist of people who work here. Our clients and partners become our team members - new
            cypressers. With every new cypresser, our Cypress tree scratches its branches, coming up with
            new challenges and supporting new brands.
          </AboutParagraph>
        </Row>
      </Container>
      <Container breakpoint={'desktop'}>
        <MemberRow>
          {memberCards}
        </MemberRow>
      </Container>
      <br/>
      <br/>
      <br/>
      <TheyClapUsSlider/>
      <br/>
      <WorkWithUsSlider/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Container>
        <Row maxWidth={1037}>
          <RowItem maxWidth={1037}>
            <JoinOurTeam/>
          </RowItem>
        </Row>
      </Container>
      <FooterDivider marginTop={50} marginBottom={50}/>
      <br/>
    </Layout>
  )
});

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title
        subtitle
        title_image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 916, srcSetBreakpoints: [749, 916], sizes: "(max-width: 1366px) calc(749px), (min-width: 1367px) calc(916px), 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image_mobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024, srcSetBreakpoints: [1024]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        members {
          name
          title
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 369, srcSetBreakpoints: [369]) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }`;
