import React, {useRef, useState} from 'react'
import Dropzone from "react-dropzone";
import ShowToast from "./toast/ShowToast";
import Title from "./title/Title";
import styled from "styled-components";
import close from "../img/clear_file_selection.svg";
import {toast} from "react-toastify";
import {media} from "./styles/MediaMixins";
import Paragraph from "./paragraph/Paragraph";

const LocalTitle = styled(Title)`
  text-align: center;
  margin-bottom: ${11 / 16}rem;
  ${media.widescreen`margin-bottom: ${16 / 16}rem`};
`

const LocalParagraph = styled(Paragraph)`
  text-align: center;
  margin-bottom: ${20 / 16}rem;
  ${media.widescreen`margin-bottom: ${55 / 16}rem`};
`

const DeleteFile = styled.button`
  height: ${25 / 16}rem;
  width: ${25 / 16}rem;
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
`

const AttachFiles = styled.p`
  &:before {
    width: 100%;
    color: #22BC88;
    font-weight: bold;
    font-size: ${24 / 16}rem;
    line-height: ${48 / 16}rem;
    display: inline-block;
    ${media.touch`content: 'Attach your CV \u00A0';`}
    ${media.desktop`content: 'Drag and Drop \u00A0';`}
  }
`

const DidAttachFiles = styled.p`
    width: 100%;
    color: #22BC88;
    font-weight: bold;
    font-size: ${24 / 16}rem;
    line-height: ${48 / 16}rem;
    display: inline-block;
`

const ExtraText = styled.p`
    color: #CCCCCC;
    font-weight: bold;
    line-height: ${48 / 16}rem;
    display: inline-block;
    ${media.touch`display: none`}
`

function encode(data) {
  const formData = new FormData()
  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }
  return formData
}

export default () => {
  const fileInputRef = useRef();
  const [cv, setCv] = useState(undefined);
  const [botField, setBotField] = useState(undefined);

  const handleChange = (e) => {
    setBotField(e.target.value);
  }

  const phoneRegexp = "(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\\s*[)]?[-\\s\\.]?[(]?[0-9]{1,3}[)]?([-\\s\\.]?[0-9]{3})([-\\s\\.]?[0-9]{3,4})";

  const accept = (files) => {
    setCv(files[0]);
  };

  const onChange = () => {
    if (fileInputRef.current &&
      fileInputRef.current.files &&
      fileInputRef.current.files[0]) {
      setCv(fileInputRef.current.files[0]);
    } else {
      setCv(undefined);
    }
  };

  let file = cv ? (`${cv.name}`) : undefined;

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target;
    if (!form.phone || !form.phone.value.match(phoneRegexp)) {
      ShowToast('warning', 'Please provide valid phone number!');
      return;
    }
    if (!form.email || form.email.value.indexOf('@') === -1) {
      ShowToast('warning', 'Please provide valid email!');
      return;
    }
    if (!cv) {
      ShowToast('warning', 'Please attach your CV!');
      return;
    }
    if (cv.size > 5 * 1024 * 1024) {
      ShowToast('warning', 'Please select a CV smaller than 5MB');
      return;
    }
    const data = {
      'form-name': form.getAttribute('name'),
      'cv': cv,
      'fullname': form.fullname.value,
      'email': form.email.value,
      'phone': form.phone.value,
      'position': form.position.value,
    };
    if (botField) {
      data['bot-field'] = botField;
      console.log("You are a bot!");
    }
    const loadingId = ShowToast('loading', 'Submitting...');
    fetch('/', {
      method: 'POST',
      body: encode(data),
    }).then(() => {
      toast.dismiss(loadingId);
      form.reset();
      setCv(null);
      setBotField(undefined);
      ShowToast('success', 'Thanks for submission!');
    }).catch((error) => {
      toast.dismiss(loadingId);
      ShowToast('error', 'Failed to submit!');
    })
  }
  const clearFile = (e) => {
    e.preventDefault();
    fileInputRef.current.value = null;
    setCv(null);
  };

  return (
    <>
      <LocalTitle textAlign={"center"}>
        Join Our Team!
      </LocalTitle>
      <LocalParagraph>
        Become A Cypresser
      </LocalParagraph>
      <form name={'JoinOurTeam'} method={"post"} netlify-honeypot={"bot-field"} data-netlify={"true"}
            onSubmit={handleSubmit}>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type={"hidden"} name={"form-name"} value={"JoinOurTeam"}/>
        <p hidden={true}>
          <label>
            Don’t fill this out: <input name={"bot-field"} onChange={handleChange}/>
          </label>
        </p>
        <div className={'columns is-multiline'}>
          <div className={'column is-12 is-6-desktop'}>
            <div className={'field'}>
              <label className={'label'}>
                Full Name
                <input required={true} name={'fullname'}
                       className={'input is-medium has-margin-top-10'} type={'text'}
                       placeholder={'Ex. Natalie Greenwood'}/>
              </label>
            </div>
          </div>
          <div className={'column is-12 is-6-desktop'}>
            <div className={'field'}>
              <label className={'label'}>
                Email
                <input required={true} name={'email'}
                       className={'input is-medium has-margin-top-10'} type={'email'}
                       placeholder={'Ex. example@gmail.com'}/>
              </label>
            </div>
          </div>
        </div>
        <div className={'columns is-multiline'}>
          <div className={'column is-12 is-6-desktop'}>
            <div className={'field'}>
              <label className={'label'}>
                Phone Number
                <input required={true}
                       name={'phone'}
                       className={'input is-medium has-margin-top-10'}
                       type={'tel'}
                       pattern={phoneRegexp}
                       placeholder={'Ex. +37400000000'}/>
              </label>
            </div>
          </div>
          <div className={'column is-12 is-6-desktop'}>
            <label className={'label'}>
              Position
              <input required={true} name={'position'}
                     className={'input is-medium has-margin-top-10'} type={'text'}
                     placeholder={'Ex. Android Developer'}/>
            </label>
          </div>
        </div>
        <Dropzone onDrop={accept} noClick={false}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} style={{border: '1px dashed #11998E'}}
                 className={'has-text-centered has-margin-bottom-40 has-margin-top-20'}>
              <label className={'label flex-row'}
                     style={{justifyContent: 'center', alignItems: 'center'}}>
                {file ?
                  <DidAttachFiles>{file}</DidAttachFiles>
                  :
                  <AttachFiles/>}
                {!file ?
                  <ExtraText>Drop your CV here</ExtraText>
                  :
                  <DeleteFile onClick={clearFile}>
                    <img src={close} alt={'eliminate selected file'}/>
                  </DeleteFile>}
                <div className={'control'}>
                  <input {...getInputProps()} className={'file-input'} type={'file'}
                         ref={fileInputRef}
                         accept={'.pdf, .doc, .docx, .txt'}
                         onChange={onChange} name={'cv'}/>
                </div>
              </label>
            </div>
          )}
        </Dropzone>

        <button type={'submit'}
                style={{width: '100%'}}
                className={'has-text-weight-bold button is-rounded button-green has-padding-top-15 has-padding-bottom-15 is-full-height'}>
          SUBMIT TO JOIN
        </button>
      </form>
    </>
  )
}